import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

function getCurrentRelativeCursorOffset() {
  const selection = window.getSelection();
  return selection && selection.rangeCount > 0
    ? selection.getRangeAt(0)?.startOffset || 0
    : 0;
}

/**
 * A div that can be edited by the user only with plain-text, which will automatically have it's contents trimmed (and user-input blocked) if it's height is exceeded.
 */
export const EditableDiv = forwardRef(function EditableDiv(
  props: {
    value: string;
    onChange: (value: string) => void;
    height?: number;
    autoFocus?: boolean;
    inputProps?: JSX.IntrinsicElements['div'];
  } & Omit<JSX.IntrinsicElements['div'], 'onChange'>,
  ref: React.Ref<HTMLDivElement>
) {
  const { value, onChange, height, autoFocus, inputProps, ...divProps } = props;
  const [focussed, setFocussed] = useState(false);
  const textRef = useRef<HTMLDivElement | null>(null);

  const setCursor = useCallback((index?: number) => {
    try {
      const node = textRef.current;
      if (!node) return;
      const range = document.createRange();
      const sel = window.getSelection();

      if (node.textContent?.length) {
        range.setStart(node.childNodes[0], index ?? node.textContent.length);
        range.collapse(true);

        sel?.removeAllRanges();
        sel?.addRange(range);
      }
    } catch (e) {}
  }, []);

  const contentEditableRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      textRef.current = node;
      node.textContent = value;
      node.focus();

      if (height) {
        let lastInputLength = 0;
        let lastCursor = getCurrentRelativeCursorOffset();
        let lastRangeSize = 0;
        let lastInputType;
        const trimContentIfOverflow = () => {
          const content = node.textContent || '';
          const overflow = node.scrollHeight >= height;
          if (overflow) {
            if (lastInputLength > 0)
              node.textContent =
                content.slice(0, lastCursor) +
                content.slice(lastCursor + lastInputLength);

            setCursor(lastCursor);
          }

          if (lastInputType === 'insertFromPaste') {
            if (overflow) {
              setCursor(lastCursor);
            } else {
              setCursor(lastCursor + lastInputLength);
            }
          }

          const cursor = getCurrentRelativeCursorOffset();

          // This gets rid of any formatting that may have been added by the user, and, replaces non-breaking spaces with normal ones (because SVGs don't support them)
          node.textContent = node.textContent;
          setCursor(cursor);
          onChange(node.textContent || '');
        };

        const onBeforeInput = (e: InputEvent) => {
          const selection = window.getSelection();
          const range =
            selection && selection.rangeCount > 0
              ? selection.getRangeAt(0)
              : null;
          if (!range) {
            e.preventDefault();
            return;
          }
          lastCursor = range.startOffset;
          lastRangeSize = range.endOffset - range.startOffset;
          lastInputType = e.inputType;
          const content = node.textContent || '';
          if (e.inputType === 'insertFromPaste') {
            const nextInput = e.dataTransfer?.getData('text/plain') || '';
            node.textContent = `${content.slice(
              0,
              lastCursor
            )}${nextInput}${content.slice(lastCursor + lastRangeSize)}`;

            lastInputLength = nextInput.length;
            e.preventDefault();
            trimContentIfOverflow();
          } else if (e.inputType.startsWith('insert')) {
            lastInputLength = e.data?.length || 0;
          }

          if (e.inputType === 'insertParagraph') {
            e.preventDefault();
          }
        };

        node.addEventListener('beforeinput', onBeforeInput);
        node.addEventListener('input', trimContentIfOverflow);

        return () => {
          node.removeEventListener('beforeinput', onBeforeInput);
          node.removeEventListener('input', trimContentIfOverflow);
        };
      }
    }
    return null;
  }, []);

  useEffect(() => {
    if (textRef.current) {
      if (textRef.current.textContent !== value) {
        textRef.current.textContent = value;
        setCursor();
      }
    }
  }, [value]);

  return (
    <div {...divProps} style={{ position: 'relative', ...props.style }}>
      {!value && !focussed ? (
        <div className="rc-text-neutral-500 rc-text-sm rc-italic rc-pointer-events-none rc-select-none rc-absolute rc-top-0 rc-bottom-0 rc-left-0 rc-right-0 rc-m-auto rc-h-min rc-w-max">
          Enter text here
        </div>
      ) : null}
      <div
        {...inputProps}
        ref={contentEditableRef}
        contentEditable
        onBlur={() => setFocussed(false)}
        onFocus={() => {
          setCursor();
          setFocussed(true);
        }}
      />
    </div>
  );
});
