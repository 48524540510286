import * as React from 'react';
import { Textarea } from '@readcloud/ui';
import C from 'classnames';
import { TestingProps } from '../../Types/Testing';

export interface ITextAreaProps extends TestingProps {
  name: string;
  size?: 'sm' | 'lg';
  type?: 'email' | 'password' | 'text' | 'radio' | 'checkbox' | 'number';
  customControl?: boolean;
  rounded?: boolean;
  flush?: boolean;
  auto?: boolean;
  className?: string;
  valid?: boolean;
  invalid?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  customLabel?: string;
  checked?: boolean;
  elKey?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  autoCompleteOff?: boolean;
  rows?: number;
  disableSpellcheck?: boolean;
  wrapOff?: boolean;
}

const sizeMapper: { [index: string]: string } = {
  lg: 'form-control-lg',
  sm: 'form-control-sm',
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      children,
      className,
      rounded,
      flush,
      auto,
      size,
      valid,
      invalid,
      placeholder,
      name,
      value,
      readOnly,
      disabled,
      onChange,
      onFocus,
      onBlur,
      testId,
      required,
      autoCompleteOff,
      elKey,
      defaultValue,
      style,
      rows,
      disableSpellcheck,
      wrapOff,
    },
    ref
  ) => {
    const baseClasses = C(
      'form-control',
      'p-3',
      size && sizeMapper[size],
      rounded && 'form-control-rounded',
      flush && 'form-control-flush',
      auto && 'form-control-auto',
      valid && 'is-valid',
      invalid && 'is-invlaid',
      className
    );

    const textareaProps = {
      ref,
      className: baseClasses,
      rows,
      readOnly,
      disabled,
      id: name,
      name,
      placeholder,
      value,
      defaultValue,
      onChange,
      onFocus,
      onBlur,
      'data-testid': testId,
      required,
      key: elKey ? `${elKey}-textarea` : undefined,
      style,
      autoComplete: autoCompleteOff ? 'off' : undefined,
      spellCheck: !disableSpellcheck,
      wrap: wrapOff ? 'off' : undefined,
      children,
    };

    return <Textarea {...textareaProps} />;
  }
);
