import { reduxTagToReaderTag, Tag } from '@readcloud/data';
import { applicationActions } from '../../reader-features';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetConnectedTagsThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  } = {},
  { dispatch, getState }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: { get: false },
    tagOpts: { get: true },
    appStateOpts: { get: false },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.tags;
    })
  );
};

export const AddConnectedTagThunkAction = async (
  payload: Tag,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxTagToReaderTag(payload);

  return await fetch(absolutePath + `/api/v14/add/tag`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  }).then(() => {
    return expectedPayload;
  });
};

export const UpdateConnectedTagThunkAction = async (
  payload: Tag,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxTagToReaderTag(payload);

  return await fetch(absolutePath + `/api/v14/update/tag?id=${payload.id}`, {
    method: 'PUT',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  }).then(() => {
    return expectedPayload;
  });
};

export const DeleteConnectedTagThunkAction = async (
  id: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + `/api/v14/delete/tag?id=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};
