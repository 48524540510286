import * as React from 'react';
import { Input as RCInput, Switch as RCSwitch } from '@readcloud/ui';
import C from 'classnames';
import { TestingProps } from '../../Types/Testing';

export interface IInputProps extends TestingProps {
  name: string;
  type?: string;
  rounded?: boolean;
  flush?: boolean;
  auto?: boolean;
  className?: string;
  valid?: boolean;
  invalid?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  innerRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  checked?: boolean;
  elKey?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  autoCompleteOff?: boolean;
  min?: number | string;
  max?: number | string;
}

const sizeMapper: { [index: string]: string } = {
  lg: 'form-control-lg',
  sm: 'form-control-sm',
};

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      className,
      type = 'text',
      valid,
      invalid,
      placeholder,
      name,
      value,
      readOnly,
      disabled,
      onChange,
      onFocus,
      onBlur,
      onClick,
      innerRef,
      testId,
      required,
      checked,
      autoCompleteOff,
      elKey,
      defaultValue,
      style,
      min,
      max,
      ...rest
    },
    ref
  ) => {
    const commonProps = {
      className: C(className, valid && 'is-valid', invalid && 'is-invalid'),
      id: name,
      name,
      value,
      defaultValue,
      disabled,
      required,
      'data-testid': testId,
      style,
      onChange,
      onFocus,
      onBlur,
      onClick,
      ref: innerRef || ref,
      ...rest,
    };

    return (
      <RCInput
        {...commonProps}
        type={type}
        placeholder={placeholder}
        readOnly={readOnly}
        autoComplete={autoCompleteOff ? 'off' : undefined}
        min={min}
        max={max}
        {...(autoCompleteOff
          ? {
              'data-lpignore': 'true',
              'data-form-type': 'other',
            }
          : {})}
      />
    );
  }
);
