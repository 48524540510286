// Roles

const ADMIN = 'ADMIN';
const RESELLER_ADMIN = 'RESELLER_ADMIN';
const SCHOOL_ADMIN = 'SCHOOL_ADMIN';
const GROUP_ADMIN = 'GROUP_ADMIN';
const TEACHER = 'TEACHER';
const STUDENT = 'STUDENT';
const PUBLIC = 'PUBLIC';

export type Roles =
  | typeof ADMIN
  | typeof RESELLER_ADMIN
  | typeof SCHOOL_ADMIN
  | typeof GROUP_ADMIN
  | typeof TEACHER
  | typeof STUDENT
  | typeof PUBLIC;

// Scopes

const U_PUBLISHER_AUTHOR = 'U_PUBLISHER_AUTHOR';
const U_POPULATE_SCHOOL_CLOUDS = 'U_POPULATE_SCHOOL_CLOUDS';
const U_SALES = 'U_SALES';
const U_OPERATIONS = 'U_OPERATIONS';
const U_LICENCES = 'U_LICENCES';

type Scopes =
  | typeof U_PUBLISHER_AUTHOR
  | typeof U_POPULATE_SCHOOL_CLOUDS
  | typeof U_SALES
  | typeof U_LICENCES
  | typeof U_OPERATIONS;

interface AuthPermissionAccess {
  read?: boolean;
  write?: boolean;
}

//for heirarchical permissions
//{orders: {admin: true, permissions: {items: {permissions: {discount: { admin: false}}}}}}
interface AuthPermissionObject extends AuthPermissionAccess {
  permissions?: { [index: string]: AuthPermissionObject };
}

export interface AuthPermissions {
  [index: string]: AuthPermissionObject;
}

// OPT IN ARRAY. PATHS IN THIS FIELD REQUIRE YOU TO SPECIFY IF THEY ARE TRUE OR FALSE, NO MATTER WHAT THE PARENT ACCESS IS.
// EVERY PATH NOT IN THIS ARRAY RECIEVES INHERITED ACCESS RIGHTS.

const optInArray = [
  'orders.items.discount',
  'institutions.resellers',
  'orders.items.salesStatus',
];

// AUTH PERMISSIONS
// ----------
// PARENTS GIVE ACCESS TO CHILDREN UNLESS OTHERWISE SPECIFIED E.G. IF ORDERS.READ === TRUE, THEN ORDERS.ITEMS.DISCOUNT MUST EQUAL FALSE TO REMOVE ACCESS.

// TO IMPROVE THIS -
// FOR EACH ACCESSOR, ALSO SPECIFY A FILTER. E.G. SCHOOL ADMINS HAVE ADMIN RIGHTS TO INSTITUTIONS, BUT ONLY THEIR OWN. ADD A RULE THAT SPECIFICES THIS AND CAN BE USED
//
const AuthPermissions: { [index: string]: AuthPermissions } = {
  ADMIN: {
    orders: {
      read: true,
      write: false,
      permissions: {
        items: {
          permissions: {
            discount: {
              read: true,
              write: true,
            },
          },
        },
      },
    },
    bundles: {
      read: true,
      write: true,
    },
    quotableItems: {
      read: true,
      write: true,
    },
    institutions: {
      read: true,
      write: true,
      permissions: {
        resellers: {
          read: true,
          write: true,
        },
        create: {
          read: true,
          write: true,
        },
      },
    },
    users: {
      read: true,
      write: true,
    },
    books: {
      read: true,
      write: true,
    },
    licences: {
      read: true,
      write: true,
    },
    transactions: {
      read: true,
      write: true,
    },
    clouds: {
      read: true,
      write: true,
    },
    appmodules: {
      read: true,
      write: true,
    },
    role_admin: {
      read: true,
      write: true,
    },
  },
  RESELLERADMIN: {
    orders: {
      read: true,
      permissions: {
        items: {
          permissions: {
            discount: {
              read: true,
            },
          },
        },
      },
    },
    bundles: {
      read: true,
    },
    quotableItems: {
      read: true,
    },
    institutions: {
      read: true,
      permissions: {
        resellers: {
          read: true,
        },
      },
    },
    users: {
      read: true,
      write: true,
    },
    books: {
      read: true,
      write: true,
    },
    licences: {
      read: true,
      write: true,
    },
    clouds: {
      read: true,
      write: true,
    },
    transactions: {
      read: true,
      write: true,
    },
    resellerUtils: {
      read: true,
      write: true,
    },
    repurchase: {
      read: true,
      write: true,
    },
    role_reselleradmin: {
      read: true,
      write: true,
    },
    timetable: { read: true, write: true },
    content: {
      read: true,
      write: true,
    },
  },
  GROUPADMIN: {
    role_groupadmin: {
      read: true,
      write: true,
    },
  },
  SCHOOLADMIN: {
    orders: {
      read: true,
    },
    institutions: {
      read: true,
      write: true,
    },
    users: {
      read: true,
      write: true,
    },
    books: {
      read: true,
    },
    clouds: {
      read: true,
    },
    role_schooladmin: {
      read: true,
      write: true,
    },
    timetable: { read: true, write: true },
    content: {
      read: true,
      write: true,
    },
  },
  TEACHER: {
    orders: {
      read: true,
      // write: true,
    },
    bundles: {
      read: true,
    },
    institutions: {
      read: true,
    },
    users: {
      read: true,
    },
    books: {
      read: true,
    },
    clouds: {
      read: true,
    },
    dashboard_teacher: {
      read: true,
    },
    role_teacher: {
      read: true,
      write: true,
    },
    content: {
      read: true,
      write: true,
    },
  },
  STUDENT: {
    users: {
      read: true,
    },
    books: {
      read: true,
    },
    clouds: {
      read: true,
    },
    role_student: {
      read: true,
      write: true,
    },
  },
  PUBLIC: {},
  U_ORDER_DISCOUNT: {
    orders: {
      read: true,
      permissions: {
        items: {
          permissions: {
            discount: { read: true, write: true },
          },
        },
      },
    },
  },
  U_OPERATIONS: {
    operations: { read: true, write: true },
    orders: {
      read: true,
      write: true,
      permissions: {
        items: {
          permissions: {
            discount: { read: true },
            salesStatus: { read: true, write: true },
          },
        },
      },
    },
    quotableItems: {
      read: true,
      write: true,
    },
    bundles: {
      read: true,
      write: true,
    },
    institutions: {
      read: true,
      write: true,
    },
  },
  U_SALES: {
    sales: { read: true, write: true },
    orders: {
      read: true,

      permissions: {
        items: {
          permissions: {
            salesStatus: { read: true, write: true },
          },
        },
      },
    },
  },
  U_LICENCES: {
    licences: {
      read: true,
      write: true,
    },
  },
  U_POPULATESCHOOLCLOUDS: {
    institutionClouds: {
      read: true,
      write: false,
    },
  },
};

// const Scopes: { [index: string]: AuthPermissions } = {
//   U_Publisher_Author: {},
//   U_PopulateSchoolClouds: {},
//   U_Sales: {
//     orders: {
//       permissions: {
//         items: {
//           permissions: {
//             discount: { read: true, write: true }
//           }
//         }
//       }
//     }
//   }
// };

export const joinPermissions = (role: Roles, scopes: Scopes[]) => {
  const userRole = role.toUpperCase();
  const userScopes = scopes.map((scope) => scope.toUpperCase());
  // assume that parents give access to children, and prioritise access over rejection.
  /*
    e.g if a permission gives read access to orders, and another permission rejects read access to orders.items.salesStatus (read === false),
    the overall permission should GIVE access to orders.items.salesStatus, unless added to the array at the top.
  */
  const permissions = [];
  permissions.push(findPermissions(AuthPermissions[userRole]));
  userScopes.forEach((userScope) => {
    permissions.push(findPermissions(AuthPermissions[userScope]));
  });
  const completePermissions = unionPermissions(permissions);
  return completePermissions;
};

const unionPermissions = (
  permissions: { [index: string]: AuthPermissionAccess }[]
) => {
  const completePermissions = {};
  permissions.forEach((permissionSet) => {
    Object.keys(permissionSet).forEach((permissionKey) => {
      completePermissions[permissionKey] = {
        read:
          (completePermissions[permissionKey] &&
            !!completePermissions[permissionKey].read) ||
          !!permissionSet[permissionKey].read,
        write:
          (completePermissions[permissionKey] &&
            !!completePermissions[permissionKey].write) ||
          !!permissionSet[permissionKey].write,
      };
    });
  });
  return completePermissions;
};

const findPermissions = (
  permission: AuthPermissionObject,
  objectPermissions?: { [index: string]: AuthPermissionAccess },
  parentPath?: string
) => {
  const objPermissions = objectPermissions || {};
  permission &&
    Object.keys(permission).forEach((permKey) => {
      const { read, write } = permission[permKey];

      const path = parentPath ? parentPath + '.' + permKey : permKey;

      // check if parent path already exists as a key in the objectPermissions.
      if (parentPath && Object.keys(objPermissions).includes(parentPath)) {
        // if the current read access is defined then use it, otherwise if it's defined in optInArray, set it to false, else use the parents access rights.
        objPermissions[path] = {
          read:
            !!read === read
              ? !!read
              : optInArray.includes(path)
              ? false
              : !!objPermissions[parentPath].read,
          write:
            !!write === write
              ? !!write
              : optInArray.includes(path)
              ? false
              : !!objPermissions[parentPath].write,
        };
      } else {
        //if there is no parent then always just default to current read/write values. undefined will be converted to false.
        objPermissions[path] = {
          read: !!read,
          write: !!write,
        };
      }
      if (
        (permission[permKey].permissions !== null ||
          permission[permKey].permissions !== undefined) &&
        typeof permission[permKey].permissions === 'object'
      ) {
        findPermissions(permission[permKey].permissions, objPermissions, path); //change it to pass objPermissions[path] to maintain the parent/child structure.
      }
    });
  return objPermissions;
};
