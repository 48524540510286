// TODO: Removed all usages of BridgeHelper, but need insight into how to replace it...if it actually needs replacing at all?

import { Annotation, UserPrefState, v14Response } from '@readcloud/data';
import {
  annotationsActions,
  bookmarksActions,
  booksActions,
  cloudsActions,
  commentsActions,
  tagsActions,
  usersActions,
} from '../../features';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { trackEvent } from '@root/libs/common/src';
import { getReaderAnalyticsContext } from '../../analytics';
const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetImmersiveTokenThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/auth_3dp/immerse', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};

export const UpdateNativeInterceptorThunkAction = (
  payload: 'on' | 'off',
  { rejectWithValue }
) => {
  return payload;
};

export const UpdateAnnoPrefsThunkAction = (
  payload: { type: string; data: Partial<Annotation['data']> },
  { getState }
) => {
  const currentDateMs = Date.now().toString();

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const annoPrefState = getState().ApplicationState.userPrefs
    .annoPrefs as Annotation['data'];
  const newPrefs: Annotation['data'] = {
    ...annoPrefState,
    [payload.type]: payload.data,
  };

  tryCatchAndNotify(() => {
    trackEvent('Webapp/AnnotationPreferencesUpdated', {
      preferences: newPrefs,
      ...getReaderAnalyticsContext(getState),
    });
  });

  fetch(
    absolutePath + `/api/v14/update/app_state?currentDateMs=${currentDateMs}`,
    {
      headers: headers,
      method: 'PUT',
      mode: 'cors',
      body: JSON.stringify([
        {
          tags: { realm: 'UserPrefState' },
          state: {
            annoPrefs: newPrefs,
          },
          date: new Date().toISOString(),
          version: '3',
        },
      ]),
    }
  );

  return { type: payload.type, data: newPrefs };
};

export const GetAllThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { getState, dispatch }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: true },
    bookOpts: { get: true },
    annotationOpts: { get: true },
    bookmarkOpts: { get: true },
    cloudOpts: {
      get: true,
      order: '-updated',
    },
    commentOpts: { get: true },
    tagOpts: { get: true },
    appStateOpts: { get: true },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    // mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(annotationsActions.setAnnotations(result?.annotations || []));
      dispatch(bookmarksActions.setBookmarks(result?.bookmarks || []));
      dispatch(booksActions.setBooks(result?.books || []));
      dispatch(cloudsActions.setClouds(result?.clouds || []));
      dispatch(tagsActions.setTags(result?.tags || []));
      dispatch(commentsActions.setComments(result?.comments || []));
      dispatch(usersActions.setUsers(result?.users || []));
      return result.serverMillisec;
    })
  );
};

export const GetDeltaAllThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { getState, dispatch }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const params = new URLSearchParams({
    millisec: getState().ApplicationState.lastPoll.allServerMillisec,
    upToVersion: '3',
    userEmail: payload.userEmail || '',
  });

  return await fetch(
    absolutePath + `/api/rc/v14/delta/all?` + params.toString(),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then(async (response) => {
    if (!response.ok) {
      throw new Error();
    }
    const { result } = await response.json();
    dispatch(annotationsActions.deltaAnnotations(result?.annotations || []));
    dispatch(bookmarksActions.deltaBookmarks(result?.bookmarks || []));
    dispatch(booksActions.deltaBooks(result?.books || []));
    dispatch(cloudsActions.deltaClouds(result?.clouds || []));
    dispatch(tagsActions.deltaTags(result?.tags || []));
    dispatch(commentsActions.deltaComments(result?.comments || []));
    dispatch(usersActions.deltaUsers(result?.users || []));
    return result.serverMillisec;
  });
};

export const GetAppConfigThunkAction = async ({}, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  return await fetch(absolutePath + `/api/rc/v14/config`, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) =>
    response.json().then(({ result }: v14Response<{ config: {} }>) => {
      return result?.config || {};
    })
  );
};
