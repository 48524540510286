import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetAllThunkAction,
  GetAppConfigThunkAction,
  GetDeltaAllThunkAction,
  GetImmersiveTokenThunkAction,
  UpdateAnnoPrefsThunkAction,
  UpdateNativeInterceptorThunkAction,
} from './thunk';
import { ApplicationState } from './types';

const initialState: ApplicationState = {
  lastPoll: {
    allServerMillisec: 0,
  },
  toolbarsOpen: false,
  annotationsSidebarOpen: false,
  bookmarksSidebarOpen: false,
  tocSidebarOpen: false,
  immersiveToken: '',
  nativeInterceptorStatus: 'off',
  online: true,
  annotationUpdateModalOpen: false,
  annotationAddModalOpen: false,
  bookmarkModalOpen: false,
  dictionaryPopoverOpen: false,
  offlineModalOpen: false,
  immersiveReaderModalOpen: false,
  immersiveWorkbookModalOpen: false,
  userPrefs: { annoPrefs: {} },
  config: {
    deltaPollingIntervalSec: 300,
    deltaPollingRealtimeIntervalSec: 30,
  },
};

const name = 'application';

const asyncActions = {
  getImmersiveToken: createAsyncThunk(
    `${name}/getImmersiveToken`,
    GetImmersiveTokenThunkAction
  ),
  updateNativeInterceptor: createAsyncThunk(
    `${name}/updateNativeInterceptor`,
    UpdateNativeInterceptorThunkAction
  ),
  updateAnnoPrefs: createAsyncThunk(
    `${name}/updateAnnoPrefs`,
    UpdateAnnoPrefsThunkAction
  ),
  getDeltaAll: createAsyncThunk(`${name}/getDeltaAll`, GetDeltaAllThunkAction),
  getAll: createAsyncThunk(`${name}/getAll`, GetAllThunkAction),
  getAppConfig: createAsyncThunk(
    `${name}/getAppConfig`,
    GetAppConfigThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateApplication(state, action: PayloadAction<Partial<ApplicationState>>) {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
    toggleToolbars(state) {
      state.toolbarsOpen = !state.toolbarsOpen;
    },
    setAllServerMilliSec(state, action) {
      state.lastPoll.allServerMillisec = action.payload;
      state.lastPoll.annotationsServerMillisec = action.payload;
      state.lastPoll.bookmarksServerMillisec = action.payload;
    },
    setAnnotationsServerMilliSec(state, action) {
      state.lastPoll.annotationsServerMillisec = action.payload;
    },
    setBookmarksServerMilliSec(state, action) {
      state.lastPoll.bookmarksServerMillisec = action.payload;
    },
    setCommentsServerMilliSec(state, action) {
      state.lastPoll.commentsServerMillisec = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getImmersiveToken.fulfilled,
      (state, action) => {
        state.immersiveToken = action.payload.access_token;
      }
    );
    builder.addCase(
      asyncActions.updateNativeInterceptor.fulfilled,
      (state, action) => {
        state.nativeInterceptorStatus = action.payload;
      }
    );
    builder.addCase(asyncActions.updateAnnoPrefs.fulfilled, (state, action) => {
      state.userPrefs.annoPrefs[action.payload.type] =
        action.payload.data[action.payload.type];
    });
    builder.addCase(asyncActions.getAppConfig.fulfilled, (state, action) => {
      state.config = action.payload;
    });
    builder.addCase(asyncActions.getAll.fulfilled, (state, action) => {
      state.lastPoll.allServerMillisec = action.payload;
      state.lastPoll.annotationsServerMillisec = action.payload;
      state.lastPoll.bookmarksServerMillisec = action.payload;
    });
    builder.addCase(asyncActions.getDeltaAll.fulfilled, (state, action) => {
      state.lastPoll.allServerMillisec = action.payload;
      state.lastPoll.annotationsServerMillisec = action.payload;
      state.lastPoll.bookmarksServerMillisec = action.payload;
    });
  },
});

const { actions, reducer } = slice;

export const applicationReducer = reducer;
export const applicationActions = { ...actions, asyncActions };
