import {
  Button as ButtonBase,
  ButtonProps as UIButtonProps,
} from '@readcloud/ui/button';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TestingProps } from '../../Types/Testing';
import C from 'classnames';

export interface IButtonProps extends TestingProps {
  variant?: string;
  transparent?: boolean;
  size?: 'sm' | 'lg';
  id?: string;
  rounded?: boolean;
  outline?: boolean;
  active?: boolean;
  title?: string;
  disabled?: boolean;
  block?: boolean;
  className?: string;
  close?: boolean;
  icon?: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseLeave?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  children?: React.ReactNode;
  submit?: boolean;
  to?: string;
  href?: string;
  download?: string;
  style?: React.CSSProperties;
  color?: string;
}

const mapVariant = (
  variant?: string,
  outline?: boolean
): UIButtonProps['variant'] => {
  if (outline) return 'outline';
  switch (variant) {
    case 'primary':
      return 'default';
    case 'secondary':
      return 'secondary';
    case 'danger':
      return 'destructive';
    case 'light':
      return 'ghost';
    case 'link':
      return 'link';
    case 'none':
      return 'none';
    default:
      return 'default';
  }
};

const mapSize = (size?: 'sm' | 'lg', icon?: boolean): UIButtonProps['size'] => {
  if (icon) return 'icon';
  switch (size) {
    case 'sm':
      return 'sm';
    case 'lg':
      return 'lg';
    default:
      return 'default';
  }
};

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      variant = 'primary',
      icon,
      id,
      size,
      children,
      rounded,
      outline,
      block,
      active,
      title,
      disabled,
      onClick,
      onMouseEnter,
      onMouseLeave,
      className,
      close,
      submit,
      to,
      href,
      download,
      testId,
      style,
      color,
      ...rest
    },
    ref
  ) => {
    if (href) {
      const mappedVariant = mapVariant(variant, outline);
      const mappedSize = mapSize(size, icon);

      return (
        <ButtonBase
          asChild
          variant={mappedVariant}
          size={mappedSize}
          className={C(
            className,
            block && 'rc-w-full',
            rounded && 'rc-rounded-full',
            active && 'rc-active'
          )}
          disabled={disabled}
          data-testid={testId}
          style={style}
          {...rest}
        >
          <a href={href} download={download} id={id} title={title}>
            {children}
          </a>
        </ButtonBase>
      );
    }

    if (to) {
      const mappedVariant = mapVariant(variant, outline);
      const mappedSize = mapSize(size, icon);

      return (
        <ButtonBase
          asChild
          variant={mappedVariant}
          size={mappedSize}
          className={C(
            className,
            block && 'rc-w-full',
            rounded && 'rc-rounded-full',
            active && 'rc-active'
          )}
          disabled={disabled}
          data-testid={testId}
          style={style}
          {...rest}
        >
          <Link to={to} id={id}>
            {children}
          </Link>
        </ButtonBase>
      );
    }

    const mappedVariant = mapVariant(variant, outline);
    const mappedSize = mapSize(size, icon);

    return (
      <ButtonBase
        ref={ref}
        type={submit ? 'submit' : 'button'}
        id={id}
        variant={mappedVariant}
        size={mappedSize}
        className={C(
          className,
          block && 'rc-w-full',
          rounded && 'rc-rounded-full',
          active && 'rc-active'
        )}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={testId}
        style={style}
        {...rest}
      >
        {children}
      </ButtonBase>
    );
  }
);

export default Button;
