import { Cloud, reduxCloudToReaderCloud } from '@readcloud/data';
import { applicationActions } from '../../reader-features';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetConnectedCloudsThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  } = {},
  { dispatch, getState }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: {
      get: true,
      offset: payload.offset,
      limit: payload.limit,
      order: '-updated',
    },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return {
        clouds: result?.clouds.map((cloud: Cloud) => ({
          ...cloud,
          permissions: result?.cloudPermissions?.[cloud.id],
        })),
        permissions: result?.permissions,
      };
    })
  );
};

export const GetAllCloudsThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  } = {},
  { dispatch, getState }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    schoolCloudOpts: {
      get: true,
      offset: payload.offset,
      limit: payload.limit,
      order: '-updated',
    },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return {
        schoolClouds: result?.schoolClouds.map((cloud: Cloud) => ({
          ...cloud,
          permissions: result?.cloudPermissions?.[cloud.id],
        })),
        permissions: result?.permissions,
      };
    })
  );
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetInstitutionCloudsThunkAction = async ({}, { getState }) => {
  //Do API HERE
  // post order to database
  // if successful update the state
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const institutionId = getState().AuthState.user.institution;

  const response = await fetch(
    absolutePath +
      '/api/rc/v14/institution/' +
      institutionId +
      '/school_clouds/all',
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  );

  return response.json().then(({ result }) => {
    return result;
  });
};

export const ListCloudForUserThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return fetch('/api/v14/list/cloud', { method: 'GET', headers: headers }).then(
    (response) => response.json().then(({ result }) => result.cloud)
  );
};

export const SearchCloudsThunkAction = async (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  try {
    return await fetch(
      'https://api.readcloud.com/api/v14/search/advanced/cloud',
      {
        method: 'POST',
        mode: 'cors',
        headers: headers,
        body: JSON.stringify(req),
      }
    ).then((response) => response.json().then(({ result }) => result));
  } catch (e) {
    console.log('Error in SearchCloudsThunkAction');
    return {
      result: {
        items: [],
        moreAvailable: false,
        nextOffset: 0,
        timeMs: Date.now(),
      },
    };
  }

  /*const res = {result: {
    items: dummyCloudData.result.items,
    moreAvailable: dummyCloudData.result.moreAvailable,
    nextOffset: dummyCloudData.result.nextOffset,
    timeMs :Date.now()
  }}*/
};

export const AddConnectedCloudThunkAction = async (
  payload: Partial<Cloud> &
    Pick<Cloud, 'admins' | 'members' | 'description' | 'name' | 'books'>,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxCloudToReaderCloud(payload);

  return await fetch(absolutePath + `/api/v14/add/cloud`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  }).then(async (res) => {
    const json = await res.json();

    return {
      ...payload,
      id: json.result.id as string,
      //if the user just created the cloud, they are the owner, and should therefore have all of the CRUD rights.
      //I can't think of a scenario where this isn't the case so appending these permissions here for localstate,
      //before a new get request is done means they will be able to instantly edit/delete the cloud without needing to refresh
      permissions: {
        cloudId: json.result.id,
        canEdit: true,
        canAddContent: true,
        canRemoveContent: true,
        canDelete: true,
      },
    };
  });
};

export const UpdateConnectedCloudThunkAction = async (
  payload: Partial<Cloud>,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxCloudToReaderCloud(payload);

  fetch(absolutePath + `/api/v14/cloud/${payload.id}`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  });
  return payload;
};

export const DeleteConnectedCloudThunkAction = async (
  id: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + `/api/v14/delete/cloud?cloudId=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};
